import { SchemaTypeDefinition } from 'sanity'

import blockContent from './blockContent'
import tag from './tag'
import post from './post'
import youTubeEmbed from './youTubeEmbed'
import communityPost from './communityPost'

export const schemaTypes = [post, tag, blockContent, communityPost]
export const schema: { types: SchemaTypeDefinition[] } = {
  types: [post, tag, blockContent, youTubeEmbed, communityPost],
}
