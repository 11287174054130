import { ScriptableContext } from 'chart.js'
import { SubscriptionTierType, Token } from '@/types'
import { GridValueFormatterParams } from '@mui/x-data-grid-premium'
import dayjs from 'dayjs'
import { SanityClient } from 'sanity'
import { schema } from '~/schemas'
import { htmlToBlocks } from '@sanity/block-tools'
import { Schema } from '@sanity/schema'
import jsdom from 'jsdom'
const { JSDOM } = jsdom

export const titleCase = (s: string) =>
  s.replace(/^_*(.)|_+(.)/g, (s: string, c: string, d: string) =>
    c ? c.toUpperCase() : ' ' + d.toUpperCase(),
  )

export const getCurrentPath = (s: string) => {
  const sArray = s.split('/')

  return sArray[sArray.length - 1]
}

export const formatDate = (s: string) => {
  const d = new Date(s)
  const year = d.getUTCFullYear()
  const month = d.getUTCMonth() + 1
  const day = d.getUTCDate()

  return `${month}/${day}/${year}`
}

export const formatDateUniformly = (s: string) => {
  const d = new Date(s)
  const year = d.getUTCFullYear()
  const month = String(d.getUTCMonth() + 1).padStart(2, '0')
  const day = String(d.getUTCDate()).padStart(2, '0')

  return `${month}-${day}-${year}`
}

export const formatDateAndTime = () => {}

export const removeElementsByClassName = (className: string): void => {
  const elements = document.querySelectorAll(`.${className}`)
  elements.forEach((element) => {
    element.remove()
  })
}

export const addDaysToDate = (date: Date, days: number) => {
  date.setDate(date.getDate() + days)

  return date
}

export function hasProperty<T extends object, K extends PropertyKey>(
  obj: T,
  key: K,
): obj is T & Record<K, unknown> {
  return key in obj
}

//The default Array.isArray() isn't safe due to back-compatibility issue
//see: https://github.com/Microsoft/TypeScript/issues/43865
//see: https://github.com/Microsoft/TypeScript/issues/17002
export function safeIsArray(arg: any): arg is unknown[] {
  return Array.isArray(arg)
}

export const decodeToken = (token: string): Token => {
  let id: string | undefined = undefined
  let first_name: string | undefined = undefined
  let last_name: string | undefined = undefined
  let email: string | undefined = undefined
  let organization_id: string | undefined = undefined
  let exp: number | undefined = NaN
  let is_system_admin: boolean | undefined = undefined
  let is_organization_admin: boolean | undefined = undefined
  let is_data_admin: boolean | undefined = undefined
  let organization_name: string | undefined = undefined
  let is_verified: boolean | undefined = undefined
  let is_active: boolean | undefined = undefined
  let subscription_tier: SubscriptionTierType | undefined = undefined

  let decodedToken: Token | null = null
  try {
    decodedToken = JSON.parse(atob(token.split('.')[1])) as Token
  } catch (error) {
    throw new Error('Failed to parse token.')
  }

  id = decodedToken?.id
  first_name = decodedToken?.first_name
  last_name = decodedToken?.last_name
  email = decodedToken?.email
  organization_id = decodedToken?.organization_id
  exp = decodedToken?.exp
  is_system_admin = decodedToken?.is_system_admin
  is_organization_admin = decodedToken?.is_organization_admin
  is_data_admin = decodedToken?.is_data_admin
  organization_name = decodedToken?.organization_name
  is_verified = decodedToken?.is_verified
  is_active = decodedToken?.is_active
  subscription_tier = decodedToken?.subscription_tier

  if (
    !id ||
    !first_name ||
    !last_name ||
    !email ||
    !organization_id ||
    !exp ||
    is_system_admin === undefined ||
    is_organization_admin === undefined ||
    is_data_admin === undefined ||
    !organization_name ||
    is_active === undefined ||
    is_verified === undefined ||
    subscription_tier === undefined
  ) {
    throw new Error('Token is of wrong format.')
  }

  return {
    id,
    first_name,
    last_name,
    email,
    organization_id,
    exp,
    is_system_admin,
    is_organization_admin,
    is_data_admin,
    organization_name,
    is_active,
    is_verified,
    subscription_tier,
  }
}

export const formatPercent = (num: number, digits: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  }).format(Number(num))
}

export const formatUsd = (num: number, digits: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
    currencyDisplay: 'narrowSymbol',
  }).format(Number(num))
}

export const formatNumber = (num: number, digits: number) => {
  const formattedNumber = Number(num).toFixed(digits)
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: digits,
  }).format(Number(formattedNumber))
}

export function formatDateQuarter(dateStr: string): string {
  const month: number = parseInt(dateStr.substring(5, 7))

  let quarter: string
  if (1 <= month && month <= 3) {
    quarter = 'Q1'
  } else if (4 <= month && month <= 6) {
    quarter = 'Q2'
  } else if (7 <= month && month <= 9) {
    quarter = 'Q3'
  } else {
    quarter = 'Q4'
  }

  const year: string = dateStr.substring(0, 4)

  return `${quarter}-${year}`
}

export const NAIfEmpty = (str: string) => {
  return str === '' ? 'N/A' : str
}

export const formatValueOfType = (type: string, value: any) => {
  if (value === undefined || value === null) {
    return ''
  }
  switch (type) {
    case 'number':
      return formatNumber(value as number, 8)
    case 'truncatedNumber':
      return formatNumber(value as number, 2)
    case 'usdAmount':
      return formatUsd(value, 2)
    case 'percent':
      return formatPercent(value, 8)
    case 'datetime':
      return formatDate(value)
    default:
      return value
  }
}

// value formatter for datagrid
export const valueFormatterOfType =
  (type: string) => (params: GridValueFormatterParams<any>) => {
    return formatValueOfType(type, params.value)
  }

export function getRandomColor() {
  function rand(min: number, max: number) {
    return min + Math.random() * (max - min)
  }

  const h = rand(1, 360)
  const s = rand(0, 100)
  const l = rand(0, 100)
  return 'hsl(' + h + ',' + s + '%,' + l + '%)'
}

export function getRandomColorArray(length: number) {
  const rng1 = new RandomNumberGenerator(1)
  const rng2 = new RandomNumberGenerator(2)
  const rng3 = new RandomNumberGenerator(3)

  const hArray = rng1.generateRandomArray(length, 1, 360)
  const sArray = rng2.generateRandomArray(length, 50, 100)
  const lArray = rng3.generateRandomArray(length, 40, 60)

  const colors = hArray.map((h, i) => {
    const s = sArray[i]
    const l = lArray[i]
    return 'hsl(' + h + ',' + s + '%,' + l + '%,1)'
  })

  return colors
}

export class RandomNumberGenerator {
  private seed: number

  constructor(seed: number) {
    this.seed = seed
  }

  private randomIntFromInterval(min: number, max: number): number {
    this.seed = (this.seed * 9301 + 49297) % 233280
    return Math.floor((this.seed / 233280) * (max - min + 1)) + min
  }

  generateRandomArray(N: number, min: number, max: number): number[] {
    const result: number[] = []
    for (let i = 0; i < N; i++) {
      result.push(this.randomIntFromInterval(min, max))
    }
    return result
  }
}

export function getGradient(
  ctx: any,
  chartArea: any,
  color1: string,
  color2: string,
  direction?: 'x' | 'y',
) {
  let gradient: CanvasGradient | null = null
  if (direction === 'x') {
    gradient = ctx.createLinearGradient(chartArea.left, 0, chartArea.right, 0)
  } else {
    gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom)
  }
  if (gradient == null) {
    return
  }
  gradient.addColorStop(0, color1)
  gradient.addColorStop(1, color2)
  return gradient
}

export const borderColorFunc = (
  color1: string,
  color2: string,
  direction?: 'x' | 'y',
) => {
  return function (context: ScriptableContext<'line'>) {
    const chart = context.chart
    const { ctx, chartArea } = chart

    if (!chartArea) {
      // This case happens on initial chart load
      return
    }
    let width = 0,
      height = 0
    const chartWidth = chartArea.right - chartArea.left
    const chartHeight = chartArea.bottom - chartArea.top
    if (width !== chartWidth || height !== chartHeight) {
      width = chartWidth
      height = chartHeight
    }
    return getGradient(ctx, chartArea, color1, color2, direction)
  }
}

export const generateBlueColors = (n: number) => {
  const darkestBlue = [14, 53, 74]
  const lightestBlue = [218, 242, 255]

  if (n === 1) {
    return [
      `#${darkestBlue.map((c) => c.toString(16).padStart(2, '0')).join('')}`,
    ]
  }

  const stepSize = darkestBlue.map(
    (component, i) => (lightestBlue[i] - component) / (n - 1),
  )

  const blueGradient = Array.from({ length: n }, (_, index) => {
    const color = darkestBlue.map((component, i) => {
      return Math.round(component + stepSize[i] * index)
        .toString(16)
        .padStart(2, '0')
    })
    return `#${color.join('')}`
  })

  return blueGradient
}

export function getContrastingRandomColor(backgroundColor: string) {
  function rand(min: number, max: number) {
    return min + Math.random() * (max - min)
  }

  const baseColor = backgroundColor
  const baseHSL = hexToHSL(baseColor)

  const hOffset = rand(90, 180) // Adjust the offset as needed for contrast
  const h = (baseHSL.h + hOffset) % 360
  const s = rand(30, 70) // Adjust the saturation range for contrast
  const l = rand(30, 70) // Adjust the lightness range for contrast

  return `hsl(${h},${s}%,${l}%)`
}

function hexToHSL(hex: string) {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)

  let h: any
  let s: any

  const max = Math.max(r, g, b)
  const min = Math.min(r, g, b)

  const l = (max + min) / 2

  if (max === min) {
    h = s = 0
  } else {
    const d = max - min
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min)

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0)
        break
      case g:
        h = (b - r) / d + 2
        break
      case b:
        h = (r - g) / d + 4
        break
      default:
        break
    }

    h /= 6
  }

  return { h: h * 360, s: s * 100, l: l * 100 }
}

export const isValidDate = (dateString: string) => {
  const date = dayjs(dateString)
  return date.isValid() && date.format('YYYY-MM-DD') === dateString
}

export const tagsFromNames = async (
  client: SanityClient,
  tagNames: string[],
) => {
  debugger
  const references = []
  for (const tagName of tagNames) {
    const tag = await client.fetch(`*[_type == "tag" && name == $name][0]`, {
      name: tagName,
    })
    references.push({
      _key: String(Math.random()),
      _type: 'reference',
      _ref: tag._id,
    })
  }
  return references
}

export const imageFromBuffer = async (client: SanityClient, buffer: Buffer) => {
  const uploadedImage = await client.assets.upload('image', buffer)
  return {
    _type: 'image',
    asset: {
      _type: 'reference',
      _ref: uploadedImage._id,
    },
  }
}

export const imageFromURL = async (client: SanityClient, imageURL: string) => {
  const imageBuffer = await fetch(imageURL).then((res) => res.arrayBuffer())
  return imageFromBuffer(client, Buffer.from(imageBuffer))
}

export const blocksFromHTML = (html: string) => {
  const blockContentType = Schema.compile(schema).get('blockContent')
  return htmlToBlocks(html, blockContentType, {
    parseHtml: (html) => new JSDOM(html).window.document,
  })
}

export const slugFromText = (text: string) => {
  return {
    _type: 'slug',
    current: text,
  }
}
