// ./schemaTypes/youTubeType/index.ts

import { defineType, defineField } from 'sanity'
import { PlayIcon } from '@sanity/icons'

const youTubeEmbed = defineType({
  name: 'youTubeEmbed',
  type: 'object',
  title: 'YouTube Embed',
  icon: PlayIcon,
  fields: [
    defineField({
      name: 'url',
      type: 'url',
      title: 'YouTube video URL',
    }),
  ],
})

export default youTubeEmbed
